import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import { GoDot, GoDotFill } from "react-icons/go";
import { useSpring, animated } from "@react-spring/web";
import Image from "next/image";

const VerticalSlider = ({ items, auto, onClick }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [showPoster, setShowPoster] = useState(true);
  const [firstView, setFirstView] = useState("text");
  const itemHeight = 270;

  const handleUpClick = useCallback(() => {
    setShowPoster(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  }, [items.length]);

  const handleDownClick = useCallback(() => {
    setShowPoster(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  }, [items.length]);

  useEffect(() => {
    if (auto) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [auto, items.length]);

  const getOpacity = useCallback(
    (index) => {
      if (index === currentIndex) return "opacity-100";
      if (
        index === (currentIndex - 1 + items.length) % items.length ||
        index === (currentIndex + 1) % items.length
      )
        return "opacity-50";
      return "opacity-0";
    },
    [currentIndex, items.length]
  );

  const config = useMemo(() => ({ tension: 170, friction: 26 }), []);
  const transformValue = useMemo(
    () => `translateY(-${currentIndex * itemHeight}px)`,
    [currentIndex, itemHeight]
  );

  const slideAnimation = useSpring({
    transform: transformValue,
    config,
  });

  const navDots = useMemo(() => {
    return (
      <>
        {currentIndex === 0 ? (
          <GoDotFill color="gray" />
        ) : (
          <GoDot color="gray" />
        )}
        {currentIndex !== 0 && currentIndex !== items.length - 1 ? (
          <GoDotFill color="gray" />
        ) : (
          <GoDot color="gray" />
        )}
        {currentIndex === items.length - 1 ? (
          <GoDotFill color="gray" />
        ) : (
          <GoDot color="gray" />
        )}
      </>
    );
  }, [currentIndex, items.length]);

  return (
    <div className="relative flex gap-6 p-4">
      <div className="flex items-center relative h-[780px] w-[960px]">
        <div
          className={`w-full h-[80%] rounded-lg ${firstView === "video" ? "z-50" : "z-10"}`}
        >
          <div className="flex flex-col transition-transform duration-500">
            <div
              onClick={() => setFirstView("video")}
              className="rounded-2xl overflow-hidden aspect-video"
            >
              {showPoster && items[currentIndex]?.poster && (
                <div
                  onClick={() =>
                    items[currentIndex]?.videoUrl && setShowPoster(false)
                  }
                >
                  <Image
                    width={0}
                    height={0}
                    sizes="100vh"
                    className="object-cover h-full w-full"
                    src={items[currentIndex]?.poster}
                    alt="poster"
                    layout="responsive"
                    priority
                  />
                  <div className="absolute top-0 left-0 h-full bg-black bg-opacity-40 flex items-center justify-center rounded-2xl" />
                </div>
              )}
              {items[currentIndex]?.videoUrl && (
                <iframe
                  onMouseEnter={() => setFirstView("video")}
                  width="100%"
                  height="100%"
                  src={items[currentIndex]?.videoUrl}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={() => setFirstView("text")}
        className={`absolute flex right-0 pt-[250px] flex-1 w-[660px] h-[810px] overflow-hidden ${firstView === "text" ? "z-50" : "z-10"}`}
      >
        <animated.div
          className="flex flex-col transition-transform duration-500"
          style={slideAnimation}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={`mb-[10px] bg-white min-h-[260px] transition-opacity duration-500 rounded-2xl ${getOpacity(index)}`}
            >
              <div className="min-h-[260px] border-2 p-6 shadow-md rounded-2xl">
                <h3
                  onClick={() => onClick(item.id)}
                  className="text-lg font-semibold hover:cursor-pointer hover:underline"
                >
                  {item.title}
                </h3>
                <p className="text-sm text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </animated.div>

        <div className="flex items-center flex-col gap-2 px-8 mt-10">
          <button
            onClick={handleUpClick}
            className="text-gray-700 text-xl hover:text-gray-900"
          >
            <FaAngleUp color="gray" />
          </button>
          {navDots}
          <button
            onClick={handleDownClick}
            className="text-gray-700 text-xl hover:text-gray-900"
          >
            <FaAngleDown color="gray" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerticalSlider;
