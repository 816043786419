import React, { useState, useCallback } from "react";
import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import CardMobile from "./CardMobile";
import { MdOutlineExpandMore, MdExpandLess } from "react-icons/md";

const Analyses = ({ analyses, onClick }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  // Function to handle slide navigation
  const handlePrevSlide = useCallback(() => {
    setActiveSlide((prev) => (prev > 0 ? prev - 3 : prev));
  }, []);

  const handleNextSlide = useCallback(() => {
    setActiveSlide((prev) => (prev < analyses.length - 3 ? prev + 3 : prev));
  }, [analyses.length]);

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 gap-y-44 gap-y-30">
        {analyses.map(
          (analysis, index) =>
            activeSlide > index - 3 && (
              <motion.div
                key={index}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 40,
                }}
              >
                <div className="mb-72">
                  <CardMobile
                    showOpen
                    item={analysis}
                    onClick={() => onClick(analysis.id)}
                  />
                </div>
              </motion.div>
            )
        )}
      </div>
      <div className="flex item-center justify-center mt-20">
        {activeSlide > 0 && (
          <IconButton onClick={handlePrevSlide}>
            <MdExpandLess color="#9B69D4" size={40} />
          </IconButton>
        )}
        {activeSlide === 0 && (
          <IconButton onClick={handleNextSlide}>
            <MdOutlineExpandMore
              className="hover:animate-bounce"
              color="#9B69D4"
              size={40}
            />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default React.memo(Analyses);
