import React, { useState, useCallback, useMemo, memo } from "react";
import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import { GoDot, GoDotFill } from "react-icons/go";
import Card from "components/programs/Card";
import CardMobile from "components/card/CardMobile";
import { MdNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";

const NavigationButton = ({ onClick, disabled, Icon }) => (
  <IconButton onClick={onClick} disabled={disabled}>
    <Icon color={disabled ? "#D3D1CF" : "#9B69D4"} size={40} />
  </IconButton>
);

const Slider = ({ items, onClick, showDots = false }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const navDots = useMemo(() => {
    return (
      <>
        {activeSlide === 0 ? (
          <GoDotFill color="#9B69D4" />
        ) : (
          <GoDot color="gray" />
        )}
        {activeSlide !== 0 && activeSlide !== items.length - 1 ? (
          <GoDotFill color="#9B69D4" />
        ) : (
          <GoDot color="gray" />
        )}
        {activeSlide === items.length - 1 ? (
          <GoDotFill color="#9B69D4" />
        ) : (
          <GoDot color="gray" />
        )}
      </>
    );
  }, [activeSlide, items.length]);

  const handlePrev = useCallback(() => {
    setActiveSlide((prev) => Math.max(prev - 1, 0));
  }, []);

  const handleNext = useCallback(() => {
    setActiveSlide((prev) => Math.min(prev + 1, items.length - 1));
  }, [items.length]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-col">
        {items.map((item, index) =>
          activeSlide === index ? (
            <motion.div
              key={index}
              initial={{ rotate: 0, scale: 0 }}
              animate={{ rotate: 0, scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 40,
              }}
            >
              <div className="w-full block md:hidden mb-72">
                <CardMobile
                  onClick={() => onClick(item.id)}
                  showOpen
                  item={item}
                />
              </div>
              <div className="w-full hidden md:block">
                <Card showOpen program={item} onClick={() => onClick(item.id)} />
              </div>
            </motion.div>
          ) : null
        )}
      </div>
      <div className="flex justify-between items-center mb-6 md:hidden">
        <NavigationButton
          onClick={handlePrev}
          disabled={activeSlide === 0}
          Icon={MdNavigateBefore}
        />
        {showDots && navDots}
        <NavigationButton
          onClick={handleNext}
          disabled={activeSlide === items.length - 1}
          Icon={MdOutlineNavigateNext}
        />
      </div>
      <div className="flex justify-end mt-6 hidden md:flex">
        <NavigationButton
          onClick={handlePrev}
          disabled={activeSlide === 0}
          Icon={MdNavigateBefore}
        />
        <NavigationButton
          onClick={handleNext}
          disabled={activeSlide === items.length - 1}
          Icon={MdOutlineNavigateNext}
        />
      </div>
    </div>
  );
};

export default memo(Slider);
